.dashboard-div{
      background-color:  #EEF6FE;
      height: 100%;
}
.main-div-admin{
    background-color: #EEF6FE;

}
.dashboard-heading{
    margin: 25px;
}
.dashboard-div .notification-card{
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 25px 62px -14px rgba(104, 132, 173, 0.1);
    border-radius: 15px;
    height: 20vh;
    margin: 0px ;
    max-width: 20%;
}


.dashboard-table-container{
    margin: 25px;
}  