
.btn-add{
    background-color: #4196EB !important;
    color: white !important ;
   
}
.table-data{
    font-family: 'inter',sans-serif !important;
    font-weight: 600 !important;
}
.table{
    margin-top: 20px;
    padding-right: 0;
}
.main-div-modal{
 
        display: flex;
        align-items: center;
        background-color: #a7cef573;
     padding: 50px;
     height: 100vh;
     width: 100vw;
       
      
}

.scrollLimits{
    max-height: 70vh;
    overflow: auto;
}

.mainCon{
    max-width: 100%;
    margin:0 10%;
    height:100%;
}




.teacher-panel-close-btn{
    position: absolute;
    top: 0px;
    right: -8px;
    font-size: 1.6rem;
}