.xyz,
.MuiButtonBase-root {
  display: flex !important;
  padding: 5px 10px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.scrollLimits {
  max-height: 70vh;
  overflow: auto;
}

th {
  background: white;
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}