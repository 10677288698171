
.notification-subHeading{
    margin-top: 40px;
}

.dateShow{
    font-size:0.8rem;
}
/* .MuiTabScrollButton-root{
    height: 100px !important;
}
.MuiSlider-root {
    color:red !important;
  } */