/* Common css*/
.authentication-section{
    background-color: #ffffff;
}
.authentication-section .container .main-form h2{
color: black;
}

.authentication-section 
.form-control {
    height: 43px;
    /* color: #090031; */
    /* -webkit-box-shadow: unset !important; */
    /* box-shadow: unset !important; */
     border: #000000 2px solid; 
    border-radius: 0px;
    background-color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 0 0 0 18px;
    font-size: 15px;
    border: 1px solid #000000;
    border-radius: 10px;
    box-sizing: border-box;
    font-weight: 600;
    font-family: 'inter',sans-serif;
}

.form-control::-webkit-input-placeholder {
    color: #9A9A9A;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .form-control:-ms-input-placeholder {
    color: #9A9A9A;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .form-control::-ms-input-placeholder {
    color: #9A9A9A;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .form-control::placeholder {
    color: #9A9A9A;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .form-control:focus {
    border-color: black;
  }
  textarea.form-control {
    height: auto;
    padding-top: 15px;
  }
  .authentication-section .auth-form{
   
    padding: 100px;
    margin-left: auto !important;
    
   
}
.authentication-section .auth-heading{
    margin-left: 10px;
    padding: 30px;
    padding-top: 0 !important;
    color: black !important;
}
.authentication-section  .form-footer span{
    font-family: 'inter',sans-serif;
    font-weight: 600;
    margin-left: 5px;
    color:  #4196EB;

}
.authentication-section  .form-footer a{
    text-decoration: underline;
    font-family: 'inter',sans-serif;
    font-weight: 600;
}
.authentication-section .svg-div{
  background-color: rgb(216, 245, 227);
  align-items: center;
  text-align: center;
  height: 100%;
}
@media (max-width:900px){
    .authentication-section .auth-form{
        padding: 0;
    }
}
.img-height-100{
  height: 100vh;
}