.experimental-th {
    font-size: 14px !important;
}

.logos{
    width: 120px;
    height:75px
}

.experimental-td {
    font-size: 14px !important;
}

.pagination-box {
    background: #fff;
    border-radius: 10px;
    margin-top: 50px;
    width: 100px;
    padding: 5px 10px;
    border: 2px solid black;
}

.pagination-box p {
    font-weight: 600;
    margin: 0;
}

.pagination-box button {
    padding: 0;
}

.center-it {
    display: flex;
    justify-content: center;
}

/* .css-1mxz8qt-MuiPaper-root{
    
} */

.dzx>.css-1mxz8qt-MuiPaper-root {
    background: none !important;

    /* border: 5px solid white; */
}

.blurry-bg {
    backdrop-filter: blur(30px);

}

.MuiTableCell-root.td-small{
    width:20px !important
}

/* .css-dwuj3p-MuiTableCell-root{
    background:none;
    backdrop-filter: blur(40px);
} */

.buttonAnalyse {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: '100px';
    height: '100px';
}

.css-pdct74-MuiTablePagination-selectLabel,
.css-levciy-MuiTablePagination-displayedRows {
    margin-bottom: 0;
}

.MuiTableCell-rMuiTableCell-root_MuiTableCell-head_MuiTableCell-stickyHeader_MuiTableCell-alignCenter_MuiTableCell-sizeSmall_css-nc6t7a-MuiTableCell-rootoot_MuiTableCell-head_MuiTableCell-stickyHeader_MuiTableCell-alignCenter {
    width: 50px;
}