.district-dashboard .dashboard-div{
      background-color:  #EEF6FE;
      height: 100%;
}
.district-dashboard .main-div-admin{
    background-color: #EEF6FE;

}
.district-dashboard .dashboard-heading{
    margin-top: 50px;
    margin-left: 0;
    margin-bottom: 36px;
}
.district-dashboard .notification-card{
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 25px 62px -14px rgba(104, 132, 173, 0.1);
    border-radius: 15px;
    height: 25vh;
    margin: 0;
    max-width: none;
    margin-bottom: 20px;
    flex-basis: 32%;
}