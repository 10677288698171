.form-analysis-student{
    margin-top: 15px;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
}
.form-analysis-student input{
    margin-bottom: 10px;
}
.form-analysis-student label{
    margin-bottom: 10px;
}

.individual-graph{
    background-color: #fff;
    width: fit-content;
    padding: 10px;
    border-radius: 8px;
    margin-top: 15px;
}
.individual-graph p{
    text-align: center;
    font-weight: 600;
    font-size: 12px;
}
.origin-exp-data-studnet th{
    font-size: 11px;
}
.origin-exp-data-student td{
    font-size: 12px;
    font-weight: 600;
}
.glass{
    position: absolute;
    top: 0px;
    filter: blur(2px);
    width: 100%;
    height: 100%;
    background-color: #636363db;

}
.glass-top{
    position: absolute;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 55px;
}
.thrust-chart-area-left-student{
    flex-basis: 71%;
}
.thrust-chart-area-right-student{
    flex-basis: 27.8%;
}
.mini-graph{
    flex-basis: 24%;
}
.angle-box{
    border: 1.5px solid #525252;
    border-radius: 5px;
    padding: 2px;
    margin-bottom: 10px;
    font-weight: 600;
    min-width: 44px;
    text-align: center;
}
.page-layout{
    padding: 10px 5%;
}
.info-card-conatiner{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: -0.75%;
}
.info-card-stats{
    padding: 10px 8px;
    background-color: #fff;
    border-radius:  8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-top: 14px;
    flex-basis: 19.25%;
    margin-right: 0.75%;
}
.info-card-stats .head1{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}
.info-card-stats .head2{
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    color: #e64a19;;
}
.desktop-view{
    display: block;
}
.mac-view{
    display: none;
}
.mobile-view{
    display: none;
}
.btn-outline-custom{
    border: 1px solid #647da1 !important;
}
.btn-outline-custom:hover{
    background: #e64a19;
    color: #fff !important;
    border: none !important;
}
@media screen and (min-width: 1110px) {
    .desktop-view{
        display: block !important;
    }
    .mac-view{
        display: none !important;
    }
    .mobile-view{
        display: none !important;
    }
    .page-layout{
        padding: 10px 1.5%;
    }
}
@media screen and (max-width: 1109px) and (min-width: 771px) {
    /* .thrust-chart-area{
        flex-basis: 100%;
    } */
    .mini-graph{
        flex-basis: 49%;
    }
    .desktop-view{
        display: none !important;
    }
    .mac-view{
        display: block !important;
    }
    .mobile-view{
        display: none !important;
    }
    .page-layout{
        padding: 10px 2%;
    }
}
@media screen and (max-width: 770px) {
    .desktop-view{
        display: none !important;
    }
    .mac-view{
        display: none !important;
    }
    .mobile-view{
        display: block !important;
    }
    .individual-graph{
        width: 100%;
    }
    .page-layout{
        padding: 10px 5%;
    }
    .info-card-conatiner{
        margin-right: -2%;
    }
    .info-card-stats{
        flex-basis: 48%;
        margin-right: 2%;
    }
}