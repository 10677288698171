.student-container {
  min-height: 100vh !important;
  background: url("../../assets/images/Bgimg.png") no-repeat center center/cover;
}

@media (max-width: 1300px) {
  .student-container {
    height: initial;
  }
}

.student-container .logo {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 120px;
}

@media (max-width: 1080px) {
  .student-container .logo {
    width: 100px;
    top: 10px;
    left: 15px;
  }
}

.student-container img {
  max-width: 100%;
}

.student-container .components {
  z-index: 10;
}

.login-container {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 940px) {
  .login-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: initial;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.login-container .login-content {
  color: #1A273B;
  font-size: 2.5rem;
  margin: 10% 0 0 0;
}

@media (max-width: 940px) {
  .login-container .login-content {
    margin-top: 20vh;
    padding-right: 2%;
    padding-bottom: 5%;
    text-align: right;
    font-size: 2rem;
    width: 100vw;
  }
}

@media (max-width: 600px) {
  .login-container .login-content {
    font-size: 1.5rem;
  }
}

.login-container .login-form {
  -webkit-backdrop-filter: blur(5px) saturate(150%);
          backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(247, 244, 244, 0.25);
  border-radius: 48px;
  border: 1px solid rgba(247, 244, 244, 0.25);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 400px;
  height: 500px;
  padding: 5%;
  padding-bottom: 2%;
  padding-top: 2%;
  margin: 10% 10% 0 8%;
}

@media (max-width: 940px) {
  .login-container .login-form {
    margin: 3% 0% 0 0%;
    margin-bottom: 10vh;
  }
}

@media (max-width: 600px) {
  .login-container .login-form {
    width: 300px;
    height: 400px;
  }
}

.login-container .login-form h1 {
  font-size: 2rem;
  color: #1A273B;
  font-weight: 500;
}

@media (max-width: 600px) {
  .login-container .login-form h1 {
    font-size: 1.5rem;
  }
}

.login-container .login-form .login-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.login-container .login-form .login-input input, select {
  background: transparent -webkit-gradient(linear, left top, left bottom, color-stop(59%, #E7EBEE00), to(#FFFFFF)) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #E7EBEE00 59%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: overlay;
  border: 1px solid #E3E3E3;
  outline: none;
  border-radius: 48px;
  padding: 5%;
  margin-top: 5%;
  color: black;
  font-size: 18px;
  width: 310px;
  height: 50px;
}
.login-container .login-form .login-input select{
  height: auto;
}
@media (max-width: 600px) {
  .login-container .login-form .login-input input, select {
    background: transparent -webkit-gradient(linear, left top, left bottom, color-stop(10%, #E7EBEE00), to(#FFFFFF)) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #E7EBEE00 10%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    width: 250px;
    height: 45px;
    font-size: 15px;
  }
}

.login-container .login-form .login-input input::-webkit-input-placeholder {
  color: #6b6a6a;
}

.login-container .login-form .login-input input:-ms-input-placeholder {
  color: #6b6a6a;
}

.login-container .login-form .login-input input::-ms-input-placeholder {
  color: #6b6a6a;
}

.login-container .login-form .login-input input::placeholder {
  color: #6b6a6a;
}

.login-container .login-form .login-btn {
  width: 310px;
  height: 50px;
  font-size: 20px;
  -webkit-box-shadow: 7px 7px 5px rgba(251, 250, 249, 0.6);
          box-shadow: 7px 7px 5px rgba(251, 250, 249, 0.6);
  border: 1px solid #F8F7F6;
  background: #ECA979 0% 0% no-repeat padding-box;
  border-radius: 32px;
  color: #1A273B;
  cursor: pointer;
  outline: none;
}

@media (max-width: 600px) {
  .login-container .login-form .login-btn {
    width: 250px;
    height: 45px;
    font-size: 16px;
  }
}
/*# sourceMappingURL=loginpage.css.map */