.form-analysis{
    margin-top: 15px;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
}
.form-analysis input{
    margin-bottom: 10px;
}
.form-analysis label{
    margin-bottom: 10px;
}

.individual-graph{
    background-color: #fff;
    width: fit-content;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
}
.individual-graph p{
    text-align: center;
    font-weight: 600;
    font-size: 12px;
}
.origin-exp-data th{
    font-size: 11px;
}
.origin-exp-data td{
    font-size: 12px;
    font-weight: 600;
}
.glass{
    position: absolute;
    top: 0px;
    filter: blur(2px);
    width: 100%;
    height: 100%;
    background-color: #636363db;

}
.glass-top{
    position: absolute;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 55px;
}
.thrust-chart-area-left{
    flex-basis: 71%;
}
.thrust-chart-area-right{
    flex-basis: 27.8%;
}
.mini-graph{
    flex-basis: 24%;
}
.angle-box{
    border: 1.5px solid #525252;
    border-radius: 5px;
    padding: 2px;
    margin-bottom: 10px;
    font-weight: 600;
    min-width: 44px;
    text-align: center;
}
@media screen and (max-width: 1024px) {
    /* .thrust-chart-area{
        flex-basis: 100%;
    } */
    .mini-graph{
        flex-basis: 49%;
    }
}