.navbar-div{
    margin-top: 20px;
    
}

.btn-bell-icon{
    background-color: #E87D0B;
    border-radius: 10px;
   padding: 10px 15px;
    color: blanchedalmond;
    border:none ;
}
.btn-dropdown{
    border: none;
    background-color: transparent;
    margin-top: -24px;
    font-size: 20px !important;
    margin-left: 16px;
}


.ul-for-submenu{
    width: max-content;
    background-color: white;
    padding: 16px;
    position: absolute;
    right: 0;
    top: auto;
    border-radius: 8px;
    animation: revealUL 200ms ease-in;
    transition: all 400ms ease-in-out;
    z-index: 9999;
}

@keyframes revealUL {
    
    from{
        opacity:  0;
    }
    to{
        opacity: 1;
    }

}