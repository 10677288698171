.studentdb-container {
  height: 100%;
  width: 100%;
  padding: 2% 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 1%;
  overflow: hidden;
  background:transparent;
}

@media (max-width: 950px) {
  .studentdb-container {
    min-height: 100vh;
  }
}

.studentdb-container .studentdb-name {
  color: #1D0C43;
  font-size: 1.4rem;
  line-height: 24px;
  width: 85vw;
}
.studentdb-container .basic-info {
  /* padding: 1%; */
  padding-top: 25px;
}

@media (max-width: 950px) {
  .studentdb-container .studentdb-name {
    
    font-size: 1.4rem;
  }
  .studentdb-container .basic-info {
    margin-top: 36px;
  }
}

.studentdb-container .studentdb-data {
  -webkit-backdrop-filter: blur(5px) saturate(150%);
          backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(247, 244, 244, 0.4);
  border-radius: 25px;
  border: 0.53px solid rgba(247, 244, 244, 0.4);
  padding: 2%;
  padding-bottom: 1%;
  padding-top: 0.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 950px) {
  .studentdb-container .studentdb-data {
    display: none;
  }
}

.studentdb-container .studentdb-data .sdb-d-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  padding: 1%;
  width: 85vw;
}

.studentdb-container .studentdb-data .sdb-d-header button {
  height: 6vh;
  width: 8vw;
  font-size: 13px;
  border-radius: 12px;
  background: #E8EBEE 0% 0% no-repeat padding-box;
  -webkit-box-shadow: -4.5px -4.5px 5px #FFFFFF;
          box-shadow: -4.5px -4.5px 5px #FFFFFF;
  border: 1px solid #E8EBEE;
}

.studentdb-container .studentdb-data .sdb-d-content {
  height: 59vh;
  width: 90vw;
  padding: 2%;
  -webkit-backdrop-filter: blur(5px) saturate(150%);
          backdrop-filter: blur(5px) saturate(150%);
  border-radius: 18px;
  border: 0.53px solid rgba(122, 121, 121, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: auto;
  overflow-x: hidden;
}

.studentdb-container .studentdb-data-mobile {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  visibility: hidden;
  overflow: auto;
  margin-top: 2vh;
}

@media (max-width: 950px) {
  .studentdb-container .studentdb-data-mobile {
    visibility: visible;
  }
}

::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}

::-webkit-scrollbar-thumb {
  border: 0.9747881293296814px solid #9e9e9e;
  border-radius: 6px;
  background: #9e9e9e;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #404F66;
}


::-webkit-scrollbar-track {
  background: transparent -webkit-gradient(linear, left top, right top, color-stop(59%, #E7EBEE4D), to(#FFFFFF)) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(90deg, #E7EBEE4D 59%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: overlay;
  border: 0.8407000303268433px solid #E3E3E3;
  border-radius: 12px;
}
/*# sourceMappingURL=studentdb.css.map */

.desktop-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 85vw;
  font-size: 15px;
  padding: 1%;
  padding-right: 0%;
  padding-top: 0.5%;
}

@media (max-width: 950px) {
  .desktop-data {
    display: none;
  }
}

.desktop-data :nth-child(1) {
  margin-left: 0%;
}


.desktop-data button {
  height: 5vh;
  width: 9vw;
  font-size: 13px;
  border-radius: 12px;
  background: #E8EBEE 0% 0% no-repeat padding-box;
  -webkit-box-shadow: -4.5px -4.5px 5px #FFFFFF;
          box-shadow: -4.5px -4.5px 5px #FFFFFF;
  border: 1px solid #E8EBEE;
  cursor: pointer;
}

.mobile-data {
  width: 260px;
  /* height: 260px; */
  -webkit-backdrop-filter: blur(5px) saturate(150%);
          backdrop-filter: blur(5px) saturate(150%);
  background-color: rgba(255, 251, 251, 0.35);
  border-radius: 18px;
  border: 0.53px solid rgba(247, 244, 244, 0.25);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4%;
  display: none;
  margin-bottom: 5vh;
  margin-left: 2vw;
}

@media (max-width: 950px) {
  .mobile-data {
    display: block;
  }
}

.mobile-data div {
  width: 100%;
  padding: 2%;
}

.mobile-data button {
  height: 5vh;
  width: 100%;
  font-size: 15px;
  border-radius: 12px;
  background: #E8EBEE 0% 0% no-repeat padding-box;
  -webkit-box-shadow: -4.5px -4.5px 5px #FFFFFF;
          box-shadow: -4.5px -4.5px 5px #FFFFFF;
  border: 1px solid #E8EBEE;
  cursor: pointer;
  margin-top: 10%;
  outline: none;
}
/*# sourceMappingURL=data.css.map */