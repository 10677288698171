.experimental-th {
    font-size: 14px !important;
}

.experimental-td {
    font-size: 14px !important;
}

.pagination-box {
    background: #fff;
    border-radius: 10px;
    margin-top: 50px;
    width: 100px;
    padding: 5px 10px;
    border: 2px solid black;
}

.pagination-box p {
    font-weight: 600;
    margin: 0;
}

.pagination-box button {
    padding: 0;
}

.table {
    margin-top: 5px !important;
    padding-right: 0;
    background-color: #fff;
}

.flextable {
    display: flex !important;
    flex-direction: row;
    width: 60px;
}

/* 
.MuiButtonBase-root.MuiTableSortLabel-root.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root {
    display: flex !important;
} */


.MuiTableCell-root>.MuiButtonBase-root {
    flex-direction: row !important;
    text-align: left !important;
    padding: 0 !important;
}

.MuiTableCell-root {
    text-align: left !important;
    min-width: 150px;
}

.activePage {
    border: none !important;
    /* background-color: blue !important; */
}

/* .work-around {
    width: calc(100% - 0px)
} */