.navbar-div{
    margin-top: 20px;
}

.btn-bell-icon{
    background-color: #E87D0B;
    border-radius: 10px;
   padding: 10px 15px;
    color: blanchedalmond;
    border:none ;
}
.btn-dropdown{
    border: none;
    background-color: transparent;
    margin-top: -20px;
    font-size: 20px !important;
}

.header{
    width:100%;
    height:50px;
    display: flex;
    align-items: center;
    padding-top:5rem;
}
.header-flexes{
    display: flex;
    width: 100%;
}
.flex-1{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.flex-2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-col{
    display: flex;
    flex-direction: column;
}

.buttonStyle{
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 3px #EDEDED59;
    border: 0.5045763850212097px solid #FFFFFF;
    width: 135px;
    padding: 10px 0 10px;
    border-radius:19px;
    border: 4px solid #fff;
}

.buttonStyles{
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 3px #EDEDED59;
    border: 0.5045763850212097px solid #FFFFFF;
    width: 135px;
    padding: 10px 0 10px;
    border-radius:19px;
    border: 4px solid #fff;
    height: 86px;
}

img.img-size{
    width:20px;
    height:32px
}

.buttonText{
    font-size: 1.05rem;
    color: black;
}
.active{
    background-color: white;
    border: 4px solid purple;
    color: purple
}