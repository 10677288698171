.popup {
    background: #242d354d;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup .form-body{
    position: relative;
    background-color: #fff;
    min-height: 300px;
    max-height: 450px;
    min-width: 600px;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding-right: 75px;
    padding-left: 75px;
    padding-bottom: 10px;
}
.popup .form-body .close-btn{
    position: absolute;
    top: 5px;
    right: 10px;
    background: transparent;
    border: none;
}