.sidebar-logo{
    width: 100%;

    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.sidebar-logo img{
    width: 70%;
}

.side-bar-sticky{
    position: fixed;
}
.w-300{
    width: 300px;
}
.sidebar-active{
    background: linear-gradient(93.53deg, #0466C8 -7.25%, #4C9EF0 106.1%);
    color: white;
}
.list-group a{
    color: #495057;
    text-decoration: none;
}
.sidebar-active a{
    color: white !important;
}
.list-group i{
    font-size: 20px;
}

