.navbar-div{
    margin-top: 20px;
    
}

.btn-bell-icon{
    background-color: #E87D0B;
    border-radius: 10px;
   padding: 10px 15px;
    color: blanchedalmond;
    border:none ;
}
.btn-dropdown{
    border: none;
    background-color: transparent;
    margin-top: -20px;
    font-size: 20px !important;
}