@font-face {
  font-family: Exo2-Regular;
  src: url(./fonts/Exo_2/static/Exo2-Regular.ttf);
}

/* .teacher-div *{
  font-family: Exo2-Regular !important;
  overflow-x: hidden;
} */
.teacher-div img.ps-4 {
  width: 25px !important;
}
.fit-cover{
  object-fit: cover !important;
}
input {
  font-family: Exo2-Regular !important;
}

.teacher-div {
  font-family: Exo2-Regular !important;
  overflow-x: hidden;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: Exo2-Regular !important;
}

tr.noBord td {
  border: none;
}

.teacher-div .fas {
  margin: 0px 6px !important;
}

.teacher-div .css-fm8t2b-MuiTableCell-root {
  padding: 6px 0px !important;
  font-size: 13px !important;
}

.teacher-div .css-14tgv6k-MuiTableCell-root {
  padding: 6px 0px !important;
  font-size: 15px !important;
}

.teacher-div .css-1hk0zqp-MuiTableCell-root {
  padding: 6px 0px !important;
  font-size: 15px !important;
}

.loginMessage-font{
  font-size: 42px !important;
}

.teacher-div .text-end1 {
  text-align: right !important;
}

.teacher-div .analyse-blur {
  background-color: gray;
  font-size: xx-large;
  padding-top: 50px;
  color: #7F2490;
  width: 100%;
  height: 100vh;
  /* filter: blur(2px); */
  text-align: center;
}

/* .teacher-div  */


.fonted {
  font-family: Exo2-Regular !important;
  overflow-x: hidden;
  /* font-weight: 500 !important; */
}

.fontAdjust-500 {
  font-weight: 500 !important;
}

.fontAdjust-700 {
  font-weight: 700 !important;
}

.teacher-div .w-100 {
  width: 100% !important;
}

.teacher-div .h-100 {
  height: 100% !important;
}

/* Button would not have a red border upon hovering */
.teacher-div .btn-primary:hover{
  border:none !important;
}




.teacher-div .MuiTableCell-root {
  text-align: center !important;
  min-width: 110px !important;
  white-space: nowrap !important;
}

.teacher-div .header-pad {
  margin-right: 10px;
}

.teacher-div .class1 {
  animation: cssAnimation 2s 2s forwards;
  /* transition: 4s; */
  position: fixed;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  width: 100%;
  height: 100%;

}

.teacher-div .class {
  animation: cssAnimation 0s 0s forwards;
  position: fixed;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  width: 100%;
  height: 100%;

}

@keyframes cssAnimation {
  to {
    background-repeat: no-repeat;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
    transform: scale(1.05);
  }
}

.teacher-div .pe1-5 {
  padding-right: 4rem !important;
}

.teacher-div h2.main-text {
  color: #F4F6F9 !important;
  text-shadow: 1px 1px 10px #020202;
  font-size: 30px;
}

.teacher-div input[type='range'] {
  overflow: hidden;
  height: 20px;
  background: transparent linear-gradient(180deg, #E8ECEF 59%, #FFFFFF 100%);
  border: 0.9460495710372925px solid #E4E4E4;
  border-radius: 17px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 60%;
  vertical-align: middle;
}

.teacher-div input#amount {
  background: #E9ECEF;
  border-radius: 24px;
  border: none;
  padding: 0;
  text-align: center;
  height: 35px;
  width: 12%;
  vertical-align: middle;
  font-weight: 600;
}

.teacher-div input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #fcfcfc;
  margin-top: -1px;
}

.teacher-div input[type='range']::-webkit-slider-thumb {
  width: 17px;
  -webkit-appearance: none;
  height: 15px;
  cursor: pointer;
  background: #7F2490;
  border: 1px solid #FCFBFA;

  border-radius: 50%;

}

.teacher-div .exp-scroll {
  position: absolute;
  bottom: 0;
  top: 60px;
}



/** FF*/
.teacher-div input[type="range"]::-webkit-range-progress {
  background-color: red;
  height: 10px;
  margin: 3px;
}

.teacher-div input[type="range"]::-webkit-range-track {
  background-color: red;
}



/* IE*/
.teacher-div input[type="range"]::-ms-fill-lower {
  background-color: red;
}

.teacher-div input[type="range"]::-ms-fill-upper {
  background-color: red;
}

.teacher-div a {
  text-decoration: none !important;
  color: #2D2D2D;
  font-family: Exo2-Regular;
}

.teacher-div a:hover {
  text-decoration: none !important;
  color: #0a58ca !important;
  font-family: Exo2-Regular;
}

.teacher-div a.disable:hover {
  text-decoration: none !important;
  color: #2D2D2D !important;
  font-family: Exo2-Regular;
}

.teacher-div h1,
.teacher-div h2,
.teacher-div h3,
.teacher-div h4,
.teacher-div h5,
.teacher-div h6 {
  font-family: Exo2-Regular;
  color: #2D2D2D !important;
}

.teacher-div .fixImg {
  width: 35px !important;
}

.teacher-div p {
  font-family: Exo2-Regular;
}

.teacher-div .text-white h2 {
  color: #Ffffff;
}

.teacher-div .login {
  height: 667px;
}

.teacher-div .login-form {
  height: 460px;
}

.teacher-div .form {
  height: 400px;
  overflow: auto;
  border-radius: 48px;
}

.teacher-div .btn {
  width: 100%;
}

.teacher-div .btn1.mt-60 {
  margin-top: 100px !important;
}

.teacher-div .btn1.mt-40 {
  margin-top: 70px !important;
}

.teacher-div .btn.mt-60 {
  margin-top: 130px;
}

.teacher-div .btn.btn-default.sign-in {
  background: #EDAA79 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  width: 100%;
  border-radius: 23px;
  color: #1A273B;
}

.teacher-div h2.form-title {
  font-size: 21px;
  color: #1A273B !important;
}

.teacher-div .btn.btn-default.forgot {
  font-size: 14px;
  color: #1A273B;
}

.teacher-div input.form-control22 {
  border-radius: 23px;
  width: 100%;
  padding: 13px 20px;
  background: #D9DCE2 0% 0% no-repeat padding-box;
  border: 1px solid #A5AFBE;
  opacity: 0.8;
  color: #7A7A7A;
}

.teacher-div input.form-control {
  border-radius: 23px;
  width: 100%;
  background: transparent linear-gradient(180deg, #E8ECEF00 59%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important;
  border: 1px solid #E4E4E4;
  color: #262626;
}

.teacher-div input.form-control::placeholder {
  color: #757575;
}

.teacher-div input.form-control2 {
  border-radius: 23px;
  width: 100%;
  background-color: white !important;
  /* background: transparent linear-gradient(180deg, #E8ECEF00 59%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important; */
  border: 1px solid #E4E4E4;
  opacity: 0.8;
  color: black !important;
}

.teacher-div input.form-controlnum {
  border-radius: 23px;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  /* background-color:#7A7A7A ; */
  /* background: transparent linear-gradient(180deg, #E8ECEF00 59%,  100%) 0% 0% no-repeat padding-box !important; */
  border: 1px solid black;
  opacity: 0.8;
  color: black;
}

.teacher-div input.form-control input[type=text]:focus {
  border-radius: 23px;
  width: 100%;
  background: transparent linear-gradient(180deg, #E8ECEF00 59%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important;
  border: 1px solid #E4E4E4;
  opacity: 0.8;
  color: #7A7A7A;
}

.teacher-div .bell-icon img {
  height: 30px;
  width: 100%;
  vertical-align: -webkit-baseline-middle;
}

.teacher-div span.info-text {
  display: block;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.teacher-div .tab-icon img {
  height: 25px;
  width: auto;
}

.teacher-div .tab-icon.active {
  display: inline-block;
  background: #fff;
  width: 26%;
  padding: 10px 0 10px;
  border-radius: 15px;
}

.teacher-div .tab-icon {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 3px #EDEDED59;
  border: 0.5045763850212097px solid #FFFFFF;
  width: 26%;
  padding: 10px 0 10px;
  border-radius: 19px;
  border: 1px solid #fff;
}

.teacher-div .px-60 {
  padding: 0 3rem;
}

.teacher-div .ps-60 {
  padding-left: 3rem !important;
}

.teacher-div .pe-60 {
  padding-right: 3rem !important;
}

.teacher-div .text-blue {
  color: #1D0C43;
}

.teacher-div .subtext h2 {
  font-size: 26px;
}

.teacher-div .maintext h2 {
  font-size: 30px;
}

.teacher-div .card-header h3 {
  font-size: 18px;
}

.teacher-div .card-header {
  background: none;
  border: none;
}

.teacher-div .bell-icon {
  display: table-cell;
}

.teacher-div .lower-section {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  background-color: rgba(255, 255, 255, 0.62);
  box-shadow: 0px 0px 24px #00000052;
  border-radius: 13px;
}

.teacher-div .lower-section2 {
  background: #E0E0E0 0% 0% no-repeat padding-box;
  background-color: rgba(224, 224, 224, 0.59);
  border: 0.8449892997741699px solid #FFFFFF;
  border-radius: 13px;
}

.teacher-div .lower-section1 {
  box-shadow: 0px 0px 7px #00000059;
  border: 2px solid #FFFFFF;
  border-radius: 19px;
  opacity: 1;
  background: #FCFCFC 0% 0% no-repeat padding-box;

}

.teacher-div .scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 750px;
}

.teacher-div .scroll-div-upper .main-text {
  color: #1A273B;
  font-size: 26px;

}

.teacher-div .main-text {
  color: #1A273B;
  font-size: 26px;

}

.teacher-div .scroll-div-upper .sub-text {
  color: #3D3D3D;
  font-size: 14px;
}

.teacher-div .name-qunt {

  background-color: rgba(252, 252, 252, 0.62);
  border-radius: 19px;
}

.teacher-div .lower-section1 h4.title {
  color: #333333 !important;
  font-size: 16px;
}

.teacher-div .lower-section1 h2.quantity {
  color: #7F2490 !important;
  font-size: 17px;
}

.teacher-div .calculator-section {
  box-shadow: 0px 0px 7px #00000059;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
}

.teacher-div .cal-titl {
  color: #1D0C43;
  font-size: 19px;

}

.teacher-div .calculator-section input[type="text"] {

  background: transparent linear-gradient(180deg, #d7dde1 70%, #FFFFFF 100%);
  border: 0.9076083302497864px solid #E4E4E4;
  padding: 8px 15px;
  border-radius: 23px;
  color: #7A7A7A;
}

.teacher-div .btn-submit {
  padding: 14px 0 4px 0;
  width: 100%;
  border-radius: 23px;
  background: #7F2490;
  color: #fff;
  border: none;
}

.teacher-div .card-header h3 {
  font-size: 18px;
  color: #2D2D2D !important;
}

.teacher-div .card {
  box-shadow: 0px 0px 7px #00000059;
  border: 2px solid #FFFFFF;
  border-radius: 25px;
  opacity: 1;
  background: #fff !important;
}

.teacher-div .bg-gray {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #FFFFFF;
}

.teacher-div .me-3r {
  margin-right: 3rem !important;
}

.teacher-div .sub-text h4 {
  font-size: 19px;
  color: #000 !important;
}

.teacher-div .main-text h2 {
  font-size: 34px;
  color: #000 !important;
}

.teacher-div .bg-light1 {
  background: #f6f7f9;
  /*#e8ecef*/
  border-radius: 19px;
}

.teacher-div .popover.bottom .arrow {
  left: 90% !important;
}

.teacher-div .bell-icon i.fa {
  font-size: 31px;
}

.teacher-div .user-profile img {
  width: 60px;
  height: auto;
}

.teacher-div .add-btn {
  font-size: 16px;
  padding: 12px 0;
  width: 75%;
  color: #1A273B !important;
  border-radius: 18px !important;
  box-shadow: 0px 0px 25px #02020252;
  border: none;
  background-color: rgba(255, 255, 255, 0.62) !important;
}

.teacher-div .add-btn:hover {
  color: #0a58ca !important;
}

.teacher-div .px-4r {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.teacher-div .pe-10r {
  padding-right: 10rem !important;
}

.teacher-div .add-btn i.fa {
  border: 1px solid #000;
  border-radius: 50%;
  color: #000
}

.teacher-div .table>:not(:first-child) {
  border-top: none;
}

.teacher-div tbody,
.teacher-div tr {
  border-color: none !important;
  border-style: solid transparent !important;

}

.teacher-div tbody.table-body tr {
  border-bottom: 1px solid #707070;
}

.teacher-div .experiments th.col1 {
  width: 2%;
}

.teacher-div .experiments th.col2 {
  width: 11%;
}

.teacher-div .table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.teacher-div .experiments th.col3 {
  width: 20%;
}

.teacher-div .experiments th {
  font-size: 14px;
}

.teacher-div .no-border tbody.table-body tr {
  border-bottom: transparent !important;
}

.teacher-div th {
  border-bottom: transparent !important;
  text-align: center;
}

.teacher-div .table td {
  border-bottom: transparent !important;
  text-align: center;
}

.teacher-div .btn-wh {
  background-color: rgba(252, 252, 252, 0.62);
  box-shadow: 0px 0px 18px #02020252;
  border-radius: 12px;
  padding: 6px 0px;
  width: 80%;
}

.text-Center {
  text-align: center;
}

.teacher-div .btn-wh2 {
  background-color: rgba(252, 252, 252, 0.62);
  box-shadow: 0px 0px 10px #02020252;
  border-radius: 12px;
  padding: 6px 8px;
  width: 100%;
}

.teacher-div .btn-wh3 {
  background-color: rgba(252, 252, 252, 0.62);
  box-shadow: 0px 0px 10px #02020252;
  border-radius: 12px;
  padding: 6px 0px;
  width: 80%;
}

.teacher-div .btn-wh1 {
  background-color: rgba(252, 252, 252, 0.62);
  box-shadow: 0px 0px 10px #02020252;
  border-radius: 12px;
  padding: 6px 9px;
}

.teacher-div .experiments tbody.table-body tr {
  border-bottom: transparent;
}

.teacher-div .devices tbody.table-body a {
  color: #7F2490;
}

.teacher-div .text-purple a {
  color: #7F2490;
}

.teacher-div .text-purple {
  color: #7F2490;
}

.teacher-div .devices-2tab tbody.table-body {
  width: 100%;
  display: inline-table;
}

.teacher-div td.tab-title {
  line-height: 2.6;
}

.teacher-div .class-btn img {
  height: 30px;
  object-fit: contain;
  width: auto;
  /*image-rendering: -moz-crisp-edges;         /* Firefox */
  /*image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  /*image-rendering: crisp-edges;*/
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.teacher-div .student-tab table.table {
  display: table;
  width: 80%;
}

.teacher-div .tab-value {
  color: #1D0C43;
  font-size: 28px;
}

.teacher-div .pagination a {

  /* color: #8E8E8E !important; */
  color: #7F2490;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.3rem;
  border: none;
  /* background-color: #7F2490; */
}

.teacher-div .page-item.active .page-link {
  background-color: #7F2490 !important;
  color: white !important;
}

.teacher-div .experiments .pagination a {

  color: #8E8E8E !important;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.2rem;
  border: none;
}

.teacher-div .device .pagination a {

  color: #8E8E8E !important;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.2rem;
  border: none;
}

.teacher-div .class-table .pagination a {

  color: #8E8E8E !important;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.2rem !important;
  border: none;
}

.teacher-div .class-table .pagination a.active {

  color: #0F0F0F !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2rem !important;
}

.teacher-div .modal-dialog {
  overflow: visible;
}

.teacher-div .modal-dialog-scrollable .modal-content {
  border-radius: 14px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  width: 85%;
  overflow: auto;
}

.teacher-div .modal-content {
  border-radius: 14px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  width: 85%;
  overflow: auto;
}

.teacher-div .check {
  background: #5EC95E;
  color: #fff;
  padding: 3px;
  border-radius: 6px;
}

.modal-header-n {
  padding: 5px;
}

.teacher-div .modal-header-n {
  border-bottom: none;
}

.teacher-div input.modal-form-control {
  border-radius: 23px;
  width: 95%;
  background: #D9DCE2 0% 0% no-repeat padding-box;
  border: 1px solid #A5AFBE;
  padding: 10px;
  color: #7A7A7A;
  padding-top: 14px;
  padding-bottom: 14px;
}

.teacher-div .pe-37 {
  padding-right: 3.75rem !important;
}

.teacher-div .modal-footer {
  border-top: none;
}

.teacher-div .col-form-label {
  color: #8e8e8e;
  font-size: 18px;
}

.teacher-div .pagination a.active {

  color: red !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3rem;
  background-color: #7F2490 !important;
}

.teacher-div .experiments .pagination a.active {

  color: #0F0F0F !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2rem;
}

.teacher-div .device .pagination a.active {

  color: #0F0F0F !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2rem;
}

.teacher-div .class-table table.table {
  width: 100%;
  display: block;
}

.teacher-div .class-table1 table.table {
  width: 80%;
}

.teacher-div .class-table1 th.col1 {
  width: 2%;
}

.teacher-div .class-table1 th.col2 {
  width: 60%;
}

.teacher-div h5.modal-title1 {
  font-size: 18px;
  color: #8E8E8E !important;
}

.teacher-div .col-form-label1 {
  color: #262526;
  font-size: 19px;
}

.teacher-div .class-table .col1 {
  width: 2%;
}

.teacher-div .st-btn .btn {
  width: auto !important;
  display: inline-block;
  padding: 10px 50px;
}

.teacher-div input.form-control1 {
  /* border-radius: 23px; */
  width: 100%;
  background: transparent linear-gradient(180deg, #E8ECEF00 59%, #FFFFFF 100%) 0% 0% no-repeat padding-box !important;
  border-bottom: 1px solid #7A7A7A;
  border-bottom: 1px solid #7A7A7A;
  border-top: none;
  border-left: none;
  border-right: none;
  opacity: 0.8;
  color: #7A7A7A;
  opacity: 1;
}

.teacher-div input.form-control1:focus {
  border: 1.5px solid black;
}

.teacher-div .page-link {
  background-color: rgba(252, 252, 252, 0.62) !important;
  padding: .375rem .45rem !important;
}

.teacher-div .page-item:not(:first-child) .page-link {
  margin-left: 0 !important;
}

.teacher-div .pagination i.fa {
  color: #7F2490 !important;
  font-size: 19px;
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: middle;
}

.teacher-div .btn-pitch {
  background-color: rgba(237, 170, 121, 0.62) !important;
  box-shadow: 0px 0px 15px #02020252;
  border-radius: 23px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.teacher-div .formTitle {
  font-size: 16px;
  color: #7A7A7A;
}

.teacher-div input[type="password"] {
  background: #D9DCE2 0% 0% no-repeat padding-box;
  border: 1px solid #A5AFBE;
  color: #262626;
  border-radius: 50px;
  padding: 5px 20px;
}

.teacher-div .sub-btn {
  color: #1A273B;
}

.teacher-div .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent !important;
}

.teacher-div .dropdown-item {
  color: #353535;
  text-decoration: none;
  background-color: transparent !important;
}

.teacher-div button#submitButton {
  background: #EDAA79 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50px;
  border: none;
  width: 50%;
  padding: 5px 0;
}

.teacher-div button.close.text-end {
  font-size: 35px;
  padding: 8px 3px 14px 2px;
  line-height: 0;
  border: 1px solid;
  border-radius: 50px;
}

.teacher-div .btn.bell-icon1:focus {
  border: none;
}

.teacher-div .dropdown-menu {
  border-radius: 10px;
  padding: 15px 15px;
}

.teacher-div .modal-header-n .btn-close {
  border: 2px solid #7f2490;
  border-radius: 50px;
  color: #707070 !important;
  opacity: 1;
  height: 35px;
  width: 35px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.teacher-div .modal-header-n .btn-close:hover {
  color: #707070 !important;
}

.teacher-div .dropdown-toggle::after {
  display: none;
}

.teacher-div .close {
  float: right;
}

.teacher-div .bell-icon1[type=button]:not(:disabled) {
  padding: 0;
}

.teacher-div #secondpopover {
  display: none;
}

.teacher-div .modal-footer {
  width: 50%;
}



/*.col1 {
    width: 8%;
}
.col2 {
    width: 42%;
}
.col3 {
    width: 50%;
}*/
.teacher-div .logo img {
  width: 120px;
  height: auto;
}



/* width */
.teacher-div .scroll-bar::-webkit-scrollbar {
  width: 12px;
  border-radius: 12px;
}

.teacher-div .scroll-bar {

  overflow-y: scroll;
  scrollbar-color: purple white;
  scrollbar-width: thin;
  scrollbar-radius: 12px;

}



/* Track */
.teacher-div ::-webkit-scrollbar {
  width: 6px;
}

.teacher-div .scroll-bar::-webkit-scrollbar-track {
  background: red;
  border-radius: 12px;
  background: transparent linear-gradient(90deg, #E8ECEF4D 59%, #FFFFFF 100%);
  border: 0.9394320845603943px solid #E4E4E4;

}

.teacher-div .page-item:first-child .page-link {
  border-top-left-radius: 0.7rem !important;
  border-bottom-left-radius: 0.7rem !important;
}

.teacher-div .page-item:last-child .page-link {
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}



/* Handle */
.teacher-div .scroll-bar::-webkit-scrollbar-thumb {
  background: #404F66;
  border-right: 3px solid #E8ECEF;
  border-left: 1px solid #fff;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  height: 1px;
  border-radius: 6px;
}



/* Handle on hover */
.teacher-div .scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 12px;
}


/*new eltr*/

.teacher-div input[type=range] {
  -webkit-appearance: none;
  margin: 0 0;
  width: 65%;
  border-radius: 12px;
  background: linear-gradient(180deg, #d7dde1 39%, #Fcfcfc 100%);
  border-top: 4px solid #E5E5E5;
  border-right: 4px solid #E5E5E5;
  border-left: 4px solid #E5E5E5;
  border-bottom: 4px solid #f1efef;
}

.teacher-div input[type=range]:focus {
  outline: none;
}

.teacher-div input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 1.3px;
  border: none;
}

.teacher-div input[type=range]::-webkit-slider-thumb {
  box-shadow: none;
  border: 1px solid #FCFBFA;
  ;
  height: 14px;
  width: 17px;
  border-radius: 50%;
  background: #7F2490;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2px;
}

.teacher-div input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

.teacher-div input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 1.3px;
  border: none;
}

.teacher-div input[type=range]::-moz-range-thumb {
  box-shadow: none;
  border: 1px solid #FCFBFA;
  ;
  height: 12px;
  width: 16px;
  border-radius: 50%;
  background: #7F2490;
  cursor: pointer;
  color: #fff;
}

.teacher-div input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

.teacher-div input[type=range]::-ms-fill-lower {
  background: #ffffff;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

.teacher-div input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

.teacher-div input[type=range]::-ms-thumb {
  box-shadow: none;

  border: 1px solid #FCFBFA;
  ;
  height: 12px;
  width: 16px;
  border-radius: 50%;
  background: #7F2490;
  cursor: pointer;
}

.teacher-div input[type=range]:focus::-ms-fill-lower {
  background: #fcfcfc;
}

.teacher-div input[type=range]:focus::-ms-fill-upper {
  background: transparent
}

@media screen and (min-width: 768px) {
  .teacher-div .col-md-22 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 22%;
    flex: 0 0 22%;
    max-width: 22%;
  }
}

@media screen and (min-width: 992px) {
  .teacher-div .dropdown-menu li {
    position: relative;
  }

  .teacher-div .dropdown-menu .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -97px;
  }

  .teacher-div .dropdown-menu .submenu-left {
    right: 100%;
    left: auto;
  }

  .teacher-div .dropdown-menu>li:hover {
    background-color: transparent
  }

  .teacher-div .dropdown-menu>li:hover>.submenu {
    display: block;
  }

  .teacher-div .table-section .col-lg-9c {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .teacher-div .table-section .col-lg-9c1 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    /* -ms-flex: 0 0 77%; */
    /* flex: 0 0 77%; */
    /* max-width: 77%; */
  }

  .teacher-div .col-lg-71 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
  }

  .teacher-div .col-lg-51 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }

  .teacher-div .table-section .col-lg-3c {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .teacher-div .col-20 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .teacher-div .col-lg-2c {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 12%;
    flex: 0 0 12%;
    max-width: 12%;
  }

  .teacher-div .col-lg-7c {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 68%;
    flex: 0 0 68%;
    max-width: 68%;
  }

  .teacher-div .col-lg-3c {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media screen and (max-width:1440px) {
  .teacher-div .mx1-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
}

.teacher-div .indexc {
  position: absolute;
  width: 100%;
  top: 17%;
}

@media screen and (min-width:1439px) {
  .teacher-div .indexc {
    bottom: 10%;
    top: unset;
  }
}

@media screen and (max-width: 991px) {
  .teacher-div .col-lg-71 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .teacher-div .col-lg-51 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .teacher-div .table-section .col-lg-9c {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .teacher-div .table-section .col-lg-9c1 {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .teacher-div .table-section .col-lg-3c {
    position: relative;
    width: 100%;
    padding: 0 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .teacher-div .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }
}

@media (min-width: 1200px) {
  .teacher-div .container {
    /* max-width: 1340px; */
    max-width: 95%;
  }
}

.teacher-div .MuiAlert-filledSuccess {
  background: rgba(0, 0, 0, 0.75);
  border-left: 6px solid #2e7d32;
}

.teacher-div .MuiAlert-filledError {
  background: rgba(0, 0, 0, 0.75);
  border-left: 6px solid #d32f2f;
}