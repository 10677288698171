.invitation-body{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url('./Slides3-bg.jpg');
}
.invitation-body form{
    background:#fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 28px;

    min-width: 375px;
    width: 100%;
    max-width: 470px;
}
.invitation-body form .form-group{
    width: 100%;
}
.invitation-body form label{
    font-weight: 500;
}
.invitation-body form .btn{
    background-color: #ff8d00;
    color: #fff;
    border: none;
}
.error-box{
    background:#fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    /* box-shadow: 0px 0px 9px #c6c6c6; */
    padding: 28px;
}
.error-box p{
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
}
.error-box{
    color: red;
}
.success-box p{
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
}
.success-box{
    background:#fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    /* box-shadow: 0px 0px 9px #c6c6c6; */
    padding: 28px;
    color: #119300;
}
.welcom-msg span{
    text-transform: capitalize;
    font-weight: 700;
    color: #ff8d00;
}