.dashboard-div{
      background-color:  #EEF6FE;
      height: 100%;
}
.main-div-admin{
    background-color: #dcf5ea;

}
.dashboard-heading{
    margin-top: 50px;
    margin-left: 0px !important;
}
.dashboard-notification-cards{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.notification-card-admin{
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 25px 62px -14px rgba(104, 132, 173, 0.1);
    border-radius: 15px;
    height: 25vh;
    margin-bottom: 20px;
    flex-basis: 32%;
    max-width: none;
}

.circularProgress{
    display: flex !important;
    height:50vh !important;
    width:100% !important;
    justify-content:center !important;
    align-items:center !important
  }