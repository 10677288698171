.district .sidebar-logo{
    width: 100%;
    margin-bottom: 65px !important;

}

.district .side-bar-sticky{
    position: fixed;
}
.district .w-300{
    width: 300px;
}
.district .sidebar-active{
    background: linear-gradient(93.53deg, #0466C8 -7.25%, #4C9EF0 106.1%);
    color: white;
}
.district .list-group a{
    color: #495057;
    text-decoration: none;
}
.district .sidebar-active a{
    color: white !important;
}
.district .list-group i{
    font-size: 20px;
}
.district .side-bar-district{
    
    margin: 0px 20px;
}
.district .list-group-item{
    border-radius: 10px !important;
    border-color: transparent;
    padding-left: 30px;
    display: flex;
    align-items: center;
    color: #495057;
    font-size: 14px;
    font-weight: 500;
}
.district .list-group-item i{
    padding: 12px 0px;
    text-align: center;
    width: 24px;
    color: #495057;
    
    padding: 12px auto;
}
.district .list-group-item svg{
    
    color: #495057;
}
.district .list-group-item span{
    margin-left: 16px;
}
.district .sidebar-active{
    border-radius: 10px !important;
    border-color: transparent;
    padding-left: 30px;
    display: flex;
    align-items: center;
    color: #fff;
    
    padding: 12px auto;
}
.district .sidebar-active i{
    text-align: center;
    width: 24px;
    color: #fff;
}
.district .sidebar-active svg{
    color: #fff;
}
