.hero-school-district .district-heading{
    margin-left: 0 !important;
    margin-top: 50px;
}
.schoolFlex{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.notification-card-district{
    background: linear-gradient(
180deg
, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 25px 62px -14px rgb(104 132 173 / 10%);
    border-radius: 15px;
    
    margin: 30px 0px;
    margin-right: 3%;
}
.btn-add{
    background-color: #4196EB !important;
    color: white !important ;
   
}
.table-data{
    font-family: 'inter',sans-serif !important;
    font-weight: 600 !important;
}
.table{
    margin-top: 20px;
    padding-right: 0;
    background-color: #fff;
}
.main-div-modal{
 
        display: flex;
        align-items: center;
        background-color: #a7cef573;
     padding: 50px;
     height: 100vh;
     width: 100vw;
       
      
}
.scrollLimits{
    max-height: 70vh !important;
    overflow: auto;
}
.table-99{
    
}
.table-99 tr{
    background-color: #fff;
}
.table-99 th{
    color: #011627;
    font-size: 18px;
    font-weight: 400;
    font-family: Inter;
}
.table-99 td{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-transform: capitalize;
}
.add-btn{
    padding: 12px 9px;
    background-color: #4196EB;
    border: none;
    border-radius: 5px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #FFFFFF;
}
.add-btn-second{
    padding: 5px 9px;
    background-color: #4196EB;
    border: none;
    border-radius: 5px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    color: #FFFFFF;
    float: right;
}

.custom-form-heading{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    /* text color */

    color: #011627;

    margin-bottom: 20px;
}
.custom-form-input{
    width: 100%;
    height: 42px;
    padding-left: 10px;
    border: 1px solid #000000;
    box-sizing: border-box;
    /* border-radius: 23px; */

    margin-bottom: 20px;
}
.custom-form-button{
    height: 48px;
    width: 100%;
    /* CTA */

    background: #4196EB;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    border: none !important;
    color: #FFFFFF;
}
.tab-btn{
    padding: 5px 20px;
    width: 50%;
    border: 1px solid #00000050;
}
.devices-table{
    margin-bottom: 5px;
    padding: 6px 5px;
    border-bottom: 0.5px solid #d1d1d1;
    display: flex;
    justify-content: space-between;
}
.assign-btn{
    color: #fff;
    padding: 2px 4px;
    border: none;
    background: #4196eb;
    border-radius: 2px;
}
.unassign-btn{
    color: #fff;
    padding: 2px 4px;
    border: none;
    background: #d86161;
    border-radius: 2px;
}