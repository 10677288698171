.sec-link{
    color:#E87D0B
}
.btn-add{
    background-color: #4196EB !important;
    color: white !important ;
   
}
table td{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    
    color: #495057;
}
table th{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    
    color: #011627;;
}
.table{
    margin-top: 20px;
    padding-right: 0;
    /* width:100% */
}
.main-div-modal{
 
        display: flex;
        align-items: center;
        background-color: #9dacbb47 !important;
     padding: 50px;
     height: 100vh;
     width: 100vw;
       
      
}

.scrollLimits{
    max-height: 70vh !important;
    overflow: auto;
}

.submit-btn{
    background-color: #4196EB !important;
    color: white !important;
}
.form-input-2{
    margin-bottom: 5px;
}

/* input[type="password"]{
    border-radius: none;
} */