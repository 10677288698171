.main-div{
    position: relative;
    z-index: 100;

    display: flex;
    align-items: center;
    background-color:#9dacbb47;
 padding: 50px;
 height: 100vh;
 width: 100vw;
   
  }

.center-div{
    position: relative;
    background-color: #f8faf9;
   
    
}
.close-btn {
    position: absolute;
    top: -14px;
    right: -16px;
    background-color: transparent !important;
    border: none;
    padding: 0 !important;
 
}

textarea.form-control{
    /* height: 70px !important; */
    max-height: auto !important;
    /* color: #090031; */
    /* -webkit-box-shadow: unset !important; */
    /* box-shadow: unset !important; */
     /* border: #000000 2px solid; 
    
    background-color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 0 0 0 18px;
    font-size: 15px;
    border: 1px solid #000000;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 600;
    font-family: 'inter',sans-serif; */
    /* resize: vertical !important; */
    overflow: auto !important;
}

.form-control {
    height: 50px;
    /* color: #090031; */
    /* -webkit-box-shadow: unset !important; */
    /* box-shadow: unset !important; */
     border: #000000 2px solid; 

    background-color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 0 0 0 18px;
    font-size: 15px;
    border: 1px solid #000000;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 600;
    font-family: 'inter',sans-serif;
}
.form-checkbox{
    border: 1px solid #4196EB;
box-sizing: border-box;
border-radius: 3px;

}